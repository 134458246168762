import moment from 'moment'
import { nanoid } from 'nanoid'
import httpClient from './httpClient'
import axios from 'axios'
window.Buffer = window.Buffer || require('buffer').Buffer
export const isAuthenticated = localStorage.getItem('token') !== null

export const scale = (num, in_min, in_max, out_min, out_max) =>
  ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min

export const getPeriod = ({ type, startDate, endDate }) => {
  switch (type) {
    case 'day':
      return { startDate: moment().subtract(24, 'hours'), endDate: moment() }
    case 'week':
      return { startDate: moment().subtract(7, 'days'), endDate: moment() }
    case 'month':
      return { startDate: moment().subtract(31, 'days'), endDate: moment() }
    case 'custom':
      return { startDate: moment(startDate), endDate: moment(endDate) }
    default:
      return {}
  }
}

const addNanoIDToName = (name) => {
  const lastIndexOfDelimeter = name?.lastIndexOf('.')
  const fileName = name?.slice(0, lastIndexOfDelimeter)
  const ext = name?.slice(lastIndexOfDelimeter + 1)
  return `${fileName?.replace(/[^\w\dА-Яа-я. -]/g, '')}_${nanoid()}.${ext}`
}

export const uploadFile = async (file) => {
  const filename = file.name ? addNanoIDToName(file.name) : nanoid()
  const { data: { payload: { url, presignedUrl }, }, } = await httpClient.post(
    `cloudServices/s3upload`,
    { file: filename, mimeType: file?.type ?? "" },
    { auth: { username: 'Fidweb1!', password: 'Off1c3AF1dw3b2020C0R0N4FTW!' } }
  );
  await axios.put(presignedUrl, file, { headers: { "Content-Type": file?.type ?? "" } });
  return { location: url };
}

export const uploadFiles = (files) => Promise.all([...files].map(uploadFile))